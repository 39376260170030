const TechStack = () => {
  return (
    <div className="p-20 pt-5 h-screen flex flex-col text-muted_gray bg-bg_grey snap-proximity snap-y">
      <div className="flex items-center justify-center p-10 snap-start">
        <h1 className="text-6xl louisgeorge">Technologies</h1>
      </div>
      <div className="grid grid-cols-4">
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Javascript</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Python</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Java</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">GoLang</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Node JS</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Express JS</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">MongoDB</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">AWS</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">CI/CD</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Test Automation</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">React</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Next JS</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Django</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">SQL</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">DevOps</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">C</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Data Analytics</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">REST APIs</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Terraform</div>
        <div className="p-5 m-10 text-2xl bg-white shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex flex-wrap justify-center gap-5 [&>*]:w-[8rem] [&>*]:h-[8rem] [&>*]:rounded-md [&>*]:bg-purple-7 [&>*]:py-4 [&>*:hover]:shadow-[0.625rem_0.625rem_0.875rem_0_rgb(225,226,228),-0.5rem_-0.5rem_1.125rem_0_rgb(255,255,255)] rounded-lg flex justify-center items-center">Git</div>
      </div>
    </div>
  );
};

export default TechStack;
